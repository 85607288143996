import React from "react";

export default function AppLink() {
  return (
    <article className="prose text-center grow max-w-6xl container mx-auto pt-16 pb-8 px-8 content-center">
      <header>
        <h1>Observatory</h1>
      </header>
      <div>
        <p>This page should redirect you to the Observatory app.</p>
      </div>
    </article>
  );
}
